<template>
  <div class="grid">
    <el-table
      :data="list"
      style="width: 100%"
      @selection-change="onSelectionChange"
    >
      <el-table-column
        v-if="selectType == 'multi'"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column v-if="selectType == 'single'" width="55" align="center">
        <template slot-scope="{ row }">
          <el-radio
            v-model="singleValue"
            :label="row[singleKey]"
            @change="onSingleChange"
          >
            <span></span>
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :type="item.type"
      >
        <template slot-scope="{ row }">
          <slot v-if="item.slot" :name="item.slot" :row="row"></slot>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <template slot="empty">
        <slot name="empty"></slot>
      </template>
    </el-table>
    <div class="footer">
      <div class="f-left">
        <slot name="footer-left"></slot>
      </div>
      <el-pagination
        class="f-right"
        background
        @current-change="onCurrentChange"
        :current-page="page"
        :page-size="limit"
        layout="prev, pager, next, total, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "grid",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: "get",
    },
    search: {
      type: Object,
      required: true,
    },
    selectType: {
      type: String,
      default: "",
    },
    singleKey: {
      default: "",
    },
    formatter: {
      type: Function,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      list: [],
      loading: false,
      singleValue: 0,
    };
  },
  async created() {
    await this.requestList(true);
  },
  methods: {
    // on event
    async onCurrentChange(page) {
      this.page = page;
      await this.requestList(false);
    },
    onSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    onSingleChange(val) {
      // val 为单选设定的key，由key来寻找行数据
      const result = this.list.filter((i) => {
        return i[this.singleKey] == val;
      });
      this.$emit("single-change", result.length ? result[0] : null);
    },
    // other
    async searchRefresh() {
      await this.requestList(true);
    },
    async refresh() {
      await this.requestList(false);
    },
    // request
    async requestList(refresh) {
      if (!this.url) {
        return;
      }
      if (refresh) {
        this.page = 1;
      }
      const params = {
        page: this.page,
        limit: this.limit,
        ...this.search,
      };
      this.loading = true;
      const data = await this.$http[this.method](this.url, params);
      this.loading = false;
      this.total = data.total;
      this.formatter && this.formatter(data.list || data.data);
      this.getData && this.getData(data);
      this.list = data.list || data.data;
      if (this.list.length == 0 && !refresh && this.page > 1) {
        this.page--;
        this.requestList(refresh);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  /deep/ .el-table {
    border: 1px solid #eee;
    th {
      background-color: #f5f5f5;
    }
    &::before {
      display: none;
    }
  }
  .footer {
    overflow: hidden;
    margin-top: 20px;
    .el-pagination {
      margin: 0;
    }
  }
}
</style>
