<template>
  <div class="member-evaluation-mistake">
    <div class="detail">
      <el-tabs @tab-click="onClickTab"
               v-model="ques_source">
        <el-tab-pane v-for="(item,index) in types"
                     :key="index"
                     :label='item.name'
                     :name="item.ques_source">
        </el-tab-pane>
      </el-tabs>
      <!-- 章节练习 -->
      <div class="courses"
           v-if="ques_source==5&&bannerList.length>0">
        <div class="nav nav-left"
             @click="navLeft"></div>
        <div class="nav nav-right"
             @click="navRight"></div>
        <div style="overflow:hidden;">
          <div class="item-container clear"
               :style="{width:bannerList.length*400+'px',marginLeft:-navLeftIndex*348+'px'}">
            <div class="item"
                 :class="{'selected':item.id==exercise_id}"
                 v-for="(item,index) in bannerList"
                 :key="index"
                 @click="changeCourse(index)">
              <div class="content">
                <div class="title">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
      </div>
      <div class="setting">
        <el-button type="text"
                   @click="onClickConsolidate('all')">巩固全部错题</el-button>
        <el-dropdown ref="autoDelete"
                     :hide-on-click="false"
                     trigger="click"
                     @visible-change="autoDelete = autoDeleteSwitch">
          <p class="remove">
            <i></i>自动移除
          </p>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-checkbox v-model="autoDelete">做对题目后自动移除该错题</el-checkbox>
              <el-button type="primary"
                         size="small"
                         @click="onSaveAutoDelete">保 存</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="tab-content-err">
        <div id="course-chapter"
             class="tab-content-plan"
             v-if="ques_source==5">
          <div class="exercise-chapter">
            <ul class="parent-chapters">
              <li class="parent-chapter-item"
                  v-for="(item,key) in error_list"
                  :key="key" v-if="item.error_count > 0">
                <div class="clearfix">
                  <div class="parent-chapter-title"
                       @click="toggleExpands(item.id)">
                    <i v-if="chapterExpand[item.id]>0"
                       class="el-icon-caret-bottom"></i>
                    <i v-else
                       class="el-icon-caret-right"></i>
                    {{ item.title }}</div>
                  <div class="chapter-count parent-title">{{item.error_count}}道错题</div>
                </div>
                <ul class="child-chapters"
                    :class=" {'expand' : chapterExpand[item.id]==1 }">
                  <li class="child-chapter-item" v-if="i.chapter_exam && i.chapter_exam.error_count"
                      v-for="(i,k) in item.children"
                      :key="k">
                    <div class="clearfix">
                      <div class="child-chapter-title">{{ i.title }}</div>
                      <div class="go_detail">
                        <img @click="goToExam(1,i.chapter_exam.paper_id)"
                             src="../../images/response@2x.png">
                      </div>
                      <div class="chapter-count">{{i.chapter_exam.error_count}}道错题</div>
                    </div>
                  </li>
                  <empty v-if="!item.children || !item.children.length"
                         :img-width="80"
                         :tb-padding="50"></empty>
                </ul>
              </li>

            </ul>
            <empty v-if="!error_list.length"
                   :img-width="120"
                   :tb-padding="50"></empty>
          </div>
        </div>
      </div>
      <!-- 模拟考试 -->
      <div class="exam_error"
           v-if="ques_source == 1">
        <ul class="parent-chapters">
          <li class="parent-chapter-item"
              v-for="(item,index) in bannerList"
              :key="index">
            <div class="exam_name">{{item.name}}</div>
            <div class="exam_count">{{item.error_count}}道错题</div>
            <img @click="goToExam(2,item.paper_id)"
                 src="../../images/response@2x.png">
          </li>
        </ul>
        <empty v-if="!bannerList.length"
               :img-width="120"
               :tb-padding="50"></empty>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
import Grid from "@/components/Grid";
export default {
  name: "member-error-list",
  components: {
    Grid, Empty
  },
  data () {
    return {
      types: [
        { name: '章节练习', ques_source: '5' },
        { name: '模拟考试', ques_source: '1' }
      ],
      error_list: [],
      ques_source: '5',
      autoDelete: false, // 用于绑定控件变化
      autoDeleteSwitch: false, // 用于保存真实的开关状态,
      bannerList: [],
      chapterExpand: {},
      swiper_index: 3,
      exercise_id: 0,
      currentIndex: 0,
      navLeftIndex: 0,
      currentExercise: {}
    };
  },
  created () {
    this.getErrorRecord();
  },
  mounted () {
  },
  computed: {
    swiperOption () {
      return {
        swiperOption: {
          autoplay: {
            delay: 3000,
            disableOnInteraction: false
          },
          navigation: {
            nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
            prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
            hideOnClick: true, //点击slide时显示/隐藏按钮
            disabledClass: "my-button-disabled", //前进后退按钮不可用时的类名。
            hiddenClass: "my-button-hidden" //按钮隐藏时的Class
          },
          loop: false,
          observer: true,
          observeParents: true,
          // slidesPerView: 6,
          // slidesPerGroup: 1,
          // spaceBetween: 16,
          //分页器设置
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      };
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    onClickTab (tab) {
      this.bannerList = [];
      this.ques_source = tab.name;
      this.getErrorRecord();
    },
    getErrorRecord () {
      this.$http.post(`/api/app/myExam/errorRecord`, { ques_source: this.ques_source }).then((res) => {
        this.bannerList = res.list;
        if (this.bannerList.length > 0 && this.ques_source == 5) {
          this.exercise_id = this.bannerList[0].id;
          this.getExercise();
        }
      });
    },
    toggleExpands (id) {
      if (this.chapterExpand[id] == 0) {
        this.chapterExpand[id] = 1;
      } else {
        this.chapterExpand[id] = 0;
      }
      this.$forceUpdate();
      console.log(this.chapterExpand);
    },
    prev () {
      if (this.swiper_index <= 3) {
        this.$message.info('前面没有更多了');
      } else {
        this.swiper.slidePrev();
        this.swiper_index = this.swiper_index - 3;
      }
    },
    next () {
      if (this.swiper_index >= this.bannerList.length) {
        this.$message.info('后面没有更多了')
      } else {
        this.swiper.slideNext();
        this.swiper_index = this.swiper_index + 3;
      }
    },
    swiper_click (item) {
      this.exercise_id = item.id;
      this.getExercise();
    },
    getExercise () {
      this.$http.get(`/api/app/myExam/error/exercise?id=${this.exercise_id}`).then((res) => {
        this.error_list = res;
        this.error_list.forEach(c => {
          this.chapterExpand[c.id] = 0;
          // if (!c.child) {
          //   return;
          // }
        })
      });
    },
    goToExam (flag, id) {
      let router = { name: "Consolidate", query: { paper_id:id , auto:false, created_at:'exercise', type: 'exercise'} }
      if (flag == 2) {
        router = { name: 'Consolidate', query: { paper_id:id , auto:false, created_at:'exam', type: 'exam' } }
      }
      this.$router.push(router);
    },
    onClickConsolidate (row) {
      this.$router.push({
        name: "Consolidate",
        query: {
          paper_id: row === 'all' ? -1 : row.paper_id,
          auto: this.autoDeleteSwitch,
          created_at: row === 'all' ? -1 : parseInt(new Date(row.created_at).getTime() / 1000)
        }
      });
    },
    onSaveAutoDelete () {
      this.$http.get(`/api/app/myExam/autoDetach/${this.autoDelete ? 1 : 0}`).then(() => {
        this.$message.success("设置成功");
        this.$refs.autoDelete.hide();
        this.autoDeleteSwitch = this.autoDelete;
      });
    },
    navLeft () {
      if (this.navLeftIndex == 0) {
        return;
      }
      this.navLeftIndex--;
    },
    navRight () {
      if (this.navLeftIndex >= this.bannerList.length - 1) {
        return;
      }
      this.navLeftIndex++;
    },
    async changeCourse (index) {
      this.currentExercise = this.bannerList[index];
      this.currentIndex = index;
      if (
        this.currentIndex >= this.navLeftIndex + 2 &&
        this.currentIndex < this.bannerList.length
      ) {
        this.navLeftIndex++;
      }
      if (this.currentIndex == this.navLeftIndex && this.currentIndex > 0) {
        this.navLeftIndex--;
      }
      this.exercise_id = this.currentExercise.id;
      this.getExercise();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../css/member-study.scss";

.swiper-box {
  margin-top: 30px;
  height: 78px;
  position: relative;
  /deep/ {
    .swiper-slide {
      width: auto;
      width: 274px !important;
      margin: 0 8px;
      text-align: center;
      .name {
        line-height: 60px;
        border: 1px solid #f4f4f4;
        border-radius: 8px;
        cursor: pointer;
        &.active {
          border: 1px solid $primary_color;
        }
      }
      .block {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 6px;
        left: 120px;
        background-color: #fff;
        border-right: 1px solid $primary_color;
        border-bottom: 1px solid $primary_color;
        transform: rotate(45deg);
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      margin-top: -30px;
      background-size: 14px 20px;
      background-color: rgba(1, 1, 1, 0.2);
    }
    .swiper-button-prev {
      left: 0px;
    }
    .swiper-button-next {
      right: 0px;
    }
  }
}
.member-evaluation-mistake {
  background-color: #f0f2f5;
  margin-bottom: 50px;
  .detail {
    background-color: #fff;
    margin-top: 30px;
    padding: 10px 20px 50px 20px;
    position: relative;
    .setting {
      position: absolute;
      top: 5px;
      right: 20px;
      display: flex;
      justify-content: space-between;
      width: 200px;
      .remove {
        font-size: 14px;
        color: #909399;
        cursor: pointer;
        i {
          display: inline-block;
          width: 30px;
          height: 40px;
          background: url("~@/assets/images/icon-setting@3x.png") no-repeat
            center;
          background-size: 16px 16px;
        }
        * {
          vertical-align: middle;
        }
      }
    }
  }
}
.exam_error {
  .parent-chapter-item {
    border-bottom: 1px dashed #efefef;
    padding: 30px 10px;
    display: flex;
    justify-content: space-between;
    .exam_name {
      width: 600px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.exercise-chapter {
  margin: 0 20px;
  .el-icon-caret-bottom,
  .el-icon-caret-right {
    color: #3b90ff;
  }
  .parent-chapters {
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 20px;
    .parent-chapter-item {
      border-bottom: 1px dashed #efefef;
      padding-bottom: 10px;
    }
    .parent-chapter-title,
    .child-chapter-title {
      float: left;
      cursor: pointer;
    }
    .chapter-count {
      float: right;
    }
    .parent-title {
      margin-right: 56px;
    }
    .go_detail {
      float: right;
      margin-left: 40px;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
    .child-chapters {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      line-height: 35px;
      padding-left: 20px;
      display: none;
      &.expand {
        display: block;
      }
    }
  }
}
.courses {
  .nav {
    top: 35px;
    padding: 19px 0 0 0;
  }
  .item-container {
    .item {
      height: 95px;
      .content {
        height: 80px;
      }
      &.selected {
        background-size: 353px 110px;
        background-position: center -10px;
      }
    }
  }
}
</style>
